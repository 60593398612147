import { db } from '../firebase';
import { 
  doc, 
  setDoc, 
  getDoc, 
  updateDoc, 
  serverTimestamp, 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  orderBy, 
  deleteDoc, 
  writeBatch 
} from 'firebase/firestore';

// 신규 사용자 생성
export async function createNewUser(user) {
  const userRef = doc(db, 'users', user.uid);
  const userData = {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    tier: 'free',
    subscription: {
      status: 'free', // free, active, cancelled, expired
      startDate: null,
      endDate: null,
      cancelDate: null,
      paymentMethod: null,
    },
    createdAt: serverTimestamp(),
    lastLoginAt: serverTimestamp(),
    usage: {
      aiAnalysisCount: 0,
      lastResetDate: serverTimestamp(),
      keywordCount: 0,
      historyCount: 0
    }
  };

  await setDoc(userRef, userData);
  return userData;
}

// 사용자 정보 조회
export async function getUserData(uid) {
  const userRef = doc(db, 'users', uid);
  const docSnap = await getDoc(userRef);
  
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
}

// 사용자 최근 로그인 시간 업데이트
export async function updateLastLogin(uid) {
  const userRef = doc(db, 'users', uid);
  await updateDoc(userRef, {
    lastLoginAt: serverTimestamp()
  });
}

// 월간 사용량 초기화 체크
export async function checkAndResetMonthlyUsage(uid) {
  const userRef = doc(db, 'users', uid);
  const userData = await getUserData(uid);
  
  if (!userData) return;

  const lastReset = userData.usage?.lastResetDate?.toDate() || new Date();
  const now = new Date();
  
  if (lastReset.getMonth() !== now.getMonth() || lastReset.getYear() !== now.getYear()) {
    // 새로운 달이 시작되면 모든 월간 카운트 리셋
    await updateDoc(userRef, {
      'usage.aiAnalysisCount': 0,
      'usage.lastResetDate': serverTimestamp()
    });
    return true;
  }
  return false;
}

// AI 분석 사용량 증가 (수정)
export async function incrementAiAnalysisCount(uid) {
  const userRef = doc(db, 'users', uid);
  const userData = await getUserData(uid);
  
  if (!userData) return;

  await checkAndResetMonthlyUsage(uid);
  
  // 카운트 증가
  await updateDoc(userRef, {
    'usage.aiAnalysisCount': (userData.usage?.aiAnalysisCount || 0) + 1
  });
}

// 키워드 개수 업데이트
export async function updateKeywordCount(uid, count) {
  const userRef = doc(db, 'users', uid);
  await updateDoc(userRef, {
    'usage.keywordCount': count
  });
}

// 뉴스 히스토리 추가
export const addNewsHistory = async (userId, newsData) => {
  const historyRef = collection(db, 'users', userId, 'newsHistory');
  await addDoc(historyRef, {
    ...newsData,
    timestamp: serverTimestamp()
  });
};

// 뉴스 히스토리 조회
export const getNewsHistory = async (userId) => {
  const historyRef = collection(db, 'users', userId, 'newsHistory');
  const q = query(historyRef, orderBy('timestamp', 'desc'));
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

// 키워드 추가
export const addKeyword = async (userId, keywordData) => {
  const keywordRef = collection(db, 'users', userId, 'keywords');
  await addDoc(keywordRef, {
    ...keywordData,
    timestamp: serverTimestamp()
  });
};

// 키워드 조회
export const getKeywords = async (userId) => {
  const keywordRef = collection(db, 'users', userId, 'keywords');
  const snapshot = await getDocs(keywordRef);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

// 분석 히스토리 추가
export const addAnalysisHistory = async (userId, analysisData) => {
  const historyRef = collection(db, 'users', userId, 'analysisHistory');
  const userRef = doc(db, 'users', userId);
  const userData = await getUserData(userId);
  
  // 무료 사용자인 경우
  if (userData.tier === 'free') {
    // 현재 히스토리 가져오기
    const q = query(historyRef, orderBy('timestamp', 'desc'));
    const snapshot = await getDocs(q);
    const histories = snapshot.docs;
    
    // 3개 이상인 경우 가장 오래된 것들 삭제
    if (histories.length >= 3) {
      const batch = writeBatch(db);
      histories.slice(2).forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
    }
  }

  // 새로운 히스토리 추가
  await addDoc(historyRef, {
    ...analysisData,
    timestamp: serverTimestamp()
  });

  // 히스토리 카운트 업데이트
  const currentCount = userData.usage?.historyCount || 0;
  let newHistoryCount;
  
  if (userData.tier === 'free') {
    // 무료 사용자는 최대 3개
    newHistoryCount = Math.min(3, currentCount + 1);
  } else {
    // 프리미엄 사용자는 제한 없음
    newHistoryCount = currentCount + 1;
  }

  await updateDoc(userRef, {
    'usage.historyCount': newHistoryCount
  });
};

// 분석 히스토리 조회
export const getAnalysisHistory = async (userId) => {
    const historyRef = collection(db, 'users', userId, 'analysisHistory');
    const q = query(historyRef, orderBy('timestamp', 'desc'));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        // Timestamp를 Date 문자열로 변환
        timestamp: data.timestamp?.toDate().toLocaleString() || new Date().toLocaleString()
      };
    });
  };

// 분석 히스토리 삭제
export const deleteAnalysisHistory = async (userId, historyId) => {
  const historyRef = doc(db, 'users', userId, 'analysisHistory', historyId);
  const userRef = doc(db, 'users', userId);
  const userData = await getUserData(userId);
  
  await deleteDoc(historyRef);
  
  // 히스토리 카운트 업데이트
  const currentCount = userData.usage?.historyCount || 0;
  const newCount = Math.max(0, currentCount - 1);
  
  await updateDoc(userRef, {
    'usage.historyCount': newCount
  });
};

export const removeKeyword = async (uid, keywordId) => {
  const keywordRef = doc(db, 'users', uid, 'keywords', keywordId);
  await deleteDoc(keywordRef);
};

export const updateKeyword = async (uid, keywordId, updatedData) => {
  const keywordRef = doc(db, 'users', uid, 'keywords', keywordId);
  await updateDoc(keywordRef, updatedData);
};

export const clearAllKeywords = async (uid) => {
  const keywordsRef = collection(db, 'users', uid, 'keywords');
  const snapshot = await getDocs(keywordsRef);
  
  const batch = writeBatch(db);
  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });
  
  await batch.commit();
  await updateKeywordCount(uid, 0);
};