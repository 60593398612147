import React, { useState, useMemo } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import TopicNewsModal from './TopicNewsModal';

function TopicAnalysisResult({ analysisResult, newsData, formatDate }) {
  const [selectedTopic, setSelectedTopic] = useState(null);

  const normalizeTopicName = (topic) => {
    return topic
      .trim()
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .replace(/[^\w\s가-힣]/g, '')
      .replace(/(관련|이슈|현황|전망|소식|뉴스|분야|시장|산업|기업|정책)$/g, '')
      .trim();
  };

  const newsIndex = newsData.reduce((acc, news) => {
    const titleOnly = news.title.split(' - ')[0];
    acc[titleOnly] = news;
    return acc;
  }, {});

  const getNewsListForTopic = (newsTitles) => {
    return newsTitles
      .map(title => {
        const titleOnly = title.split(' - ')[0];
        return newsIndex[titleOnly];
      })
      .filter(Boolean);
  };

  const validTopics = useMemo(() => {
    try {
      const parsed = typeof analysisResult === 'string' 
        ? JSON.parse(analysisResult) 
        : analysisResult;
      
      if (!parsed || !parsed.categorized_topics) {
        return [];
      }

      const topicMap = new Map();
      
      parsed.categorized_topics.forEach(category => {
        if (!category.topic || !Array.isArray(category.news_titles)) {
          return;
        }

        const normalizedTopic = normalizeTopicName(category.topic);
        const newsList = getNewsListForTopic(category.news_titles);
        
        if (topicMap.has(normalizedTopic)) {
          const existing = topicMap.get(normalizedTopic);
          const shorterTopic = existing.topic.length <= category.topic.length 
            ? existing.topic 
            : category.topic;
          existing.topic = shorterTopic;
          existing.newsList = [...new Set([...existing.newsList, ...newsList])];
        } else {
          topicMap.set(normalizedTopic, {
            topic: category.topic,
            newsList: newsList
          });
        }
      });

      return Array.from(topicMap.values())
        .filter(category => category.newsList.length >= 3)
        .sort((a, b) => b.newsList.length - a.newsList.length);

    } catch (error) {
      return [];
    }
  }, [analysisResult, newsIndex]);

  if (validTopics.length === 0) {
    return <div className="text-gray-500 text-center py-4">분석된 주제가 없습니다.</div>;
  }

  return (
    <div className="space-y-2">
      {validTopics.map((category, idx) => (
        <div key={idx} className="block bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 p-4 sm:p-6">
          <div className="flex justify-between items-center mb-4">
            <div>
              <h3 className="text-lg font-semibold text-blue-600">
                {category.topic}
                <span className="ml-2 text-sm text-gray-500">
                  ({category.newsList.length}건)
                </span>
              </h3>
            </div>
            <button
              onClick={() => setSelectedTopic({
                topic: category.topic,
                newsList: category.newsList
              })}
              className="text-sm text-gray-600 hover:text-gray-800 flex items-center gap-1"
            >
              더보기
              <FaChevronRight className="w-3 h-3" />
            </button>
          </div>

          {category.newsList.slice(0, 1).map((news, newsIdx) => (
            <a
              key={newsIdx}
              href={news.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block"
            >
              <h4 className="text-base sm:text-lg font-semibold text-gray-800 mb-2">
                {news.title}
              </h4>
              <div className="flex flex-wrap items-center gap-2 text-sm text-gray-500">
                <span>{news.source}</span>
                <span>•</span>
                <time>{formatDate(news.pubDate)}</time>
              </div>
            </a>
          ))}
        </div>
      ))}
      {selectedTopic && (
        <TopicNewsModal
          topic={selectedTopic.topic}
          newsList={selectedTopic.newsList}
          formatDate={formatDate}
          onClose={() => setSelectedTopic(null)}
        />
      )}
    </div>
  );
}

export default TopicAnalysisResult; 