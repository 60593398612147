function TodayAI() {
  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6">
      <div className="bg-white rounded-lg shadow-sm p-8 text-center">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">
          Today AI
        </h1>
        <p className="text-gray-600">
          곧 서비스가 제공될 예정입니다.
        </p>
      </div>
    </div>
  );
}

export default TodayAI; 