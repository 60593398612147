import { FaTimes } from 'react-icons/fa';
import KeywordFilter from './KeywordFilter';

function KeywordFilterModal({ keywords, activeKeywords, onToggleKeyword, newsCountByKeyword, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-md max-h-[80vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">키워드 필터</h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <FaTimes />
            </button>
          </div>
          <KeywordFilter
            keywords={keywords}
            activeKeywords={activeKeywords}
            onToggleKeyword={onToggleKeyword}
            newsCountByKeyword={newsCountByKeyword}
          />
        </div>
        <div className="border-t p-4 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
}

export default KeywordFilterModal; 