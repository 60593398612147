import React from 'react';

function KeywordFilter({ keywords, activeKeywords, onToggleKeyword, newsCountByKeyword }) {
  return (
    <div className="space-y-2">
      {keywords.map(keyword => (
        <button
          key={keyword.id}
          onClick={() => onToggleKeyword(keyword)}
          className={`w-full text-left px-4 py-2 rounded-lg flex items-center justify-between ${
            activeKeywords.some(ak => ak.id === keyword.id)
              ? 'bg-blue-50 text-blue-600'
              : 'hover:bg-gray-50'
          }`}
        >
          <span>{keyword.text}</span>
          <span className={`text-sm ${
            activeKeywords.some(ak => ak.id === keyword.id)
              ? 'text-blue-600'
              : 'text-gray-500'
          }`}>
            {newsCountByKeyword[keyword.text] || 0}
          </span>
        </button>
      ))}
    </div>
  );
}

export default KeywordFilter;
