import React, { useState, useEffect } from 'react';
import { FaPlus, FaTimes, FaGlobe, FaEdit, FaTrash, FaSave, FaCrown } from 'react-icons/fa';
import { useSubscriptionLimits } from '../../hooks/useSubscriptionLimits';

// AddKeywordModal 컴포넌트
const AddKeywordModal = ({ onClose, onAddKeyword }) => {
  const [newKeyword, setNewKeyword] = useState('');

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">새 키워드 추가</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>
        <input
          type="text"
          value={newKeyword}
          onChange={(e) => setNewKeyword(e.target.value)}
          className="w-full border rounded-lg px-4 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="키워드를 입력하세요"
          autoFocus
          onKeyPress={(e) => {
            if (e.key === 'Enter' && newKeyword.trim()) {
              onAddKeyword({
                id: Date.now().toString(),
                text: newKeyword.trim(),
                country: /^[A-Za-z\s]*$/.test(newKeyword) ? 'US' : 'KR'
              });
              setNewKeyword('');
              onClose();
            }
          }}
        />
        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
          >
            취소
          </button>
          <button
            onClick={() => {
              if (newKeyword.trim()) {
                onAddKeyword({
                  id: Date.now().toString(),
                  text: newKeyword.trim(),
                  country: /^[A-Za-z\s]*$/.test(newKeyword) ? 'US' : 'KR'
                });
                setNewKeyword('');
                onClose();
              }
            }}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            추가
          </button>
        </div>
      </div>
    </div>
  );
};

// EditKeywordModal 컴포넌트
const EditKeywordModal = ({ keyword, onClose, onUpdate }) => {
  const [editedText, setEditedText] = useState(keyword?.text || '');

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h3 className="text-lg font-semibold mb-4">키워드 수정</h3>
        <input
          type="text"
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          className="w-full border rounded-lg px-4 py-2 mb-4"
          placeholder="키워드를 입력하세요"
          autoFocus
        />
        <div className="flex justify-end gap-2">
          <button onClick={onClose} className="px-4 py-2 text-gray-600">
            취소
          </button>
          <button
            onClick={() => {
              if (editedText.trim()) {
                onUpdate({ ...keyword, text: editedText.trim() });
                onClose();
              }
            }}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
          >
            수정
          </button>
        </div>
      </div>
    </div>
  );
};

// 구독 안내 모달 컴포넌트 추가
const SubscriptionModal = ({ onClose, onUpgrade }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <FaCrown className="text-yellow-500" />
            <h3 className="text-lg font-semibold">프리미엄 기능</h3>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>
        <div className="mb-6">
          <p className="text-gray-600 mb-4">
            무료 계정은 최대 3개의 키워드만 등록할 수 있습니다.
          </p>
        </div>
        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
          >
            닫기
          </button>
          <button
            onClick={() => {
              onClose();
              onUpgrade();
            }}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            프리미엄 구독
          </button>
        </div>
      </div>
    </div>
  );
};

function KeywordManager({ 
  keywords, 
  onAddKeyword, 
  onUpdateKeyword, 
  onRemoveKeyword, 
  onSave, 
  onClearAll,
  hasChanges,
  onUpgrade 
}) {
  const { isWithinLimits, limits } = useSubscriptionLimits();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [editingKeyword, setEditingKeyword] = useState(null);
  const [selectedKeywordId, setSelectedKeywordId] = useState(null);

  const selectedKeywords = keywords?.selected || [];
  const recommendedKeywords = keywords?.recommended || [];

  // 키워드 추가 핸들러
  const handleAddKeyword = (keyword) => {
    onAddKeyword(keyword);
  };

  // 키워드 수정 핸들러
  const handleUpdateKeyword = (keyword) => {
    onUpdateKeyword(keyword);
  };

  // 키워드 삭제 핸들러
  const handleRemoveKeyword = (keywordId) => {
    onRemoveKeyword(keywordId);
  };

  // 키워드 추가 버튼 클릭 핸들러 추가
  const handleAddButtonClick = () => {
    if (!isWithinLimits.keywords(keywords.selected.length + 1)) {
      setIsSubscriptionModalOpen(true);
      return;
    }
    setIsAddModalOpen(true);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">키워드 관리</h1>
        <div className="flex gap-2">
          {hasChanges && (
            <button
              onClick={onSave}
              className="px-4 py-2 bg-green-500 text-white rounded-lg flex items-center gap-2 hover:bg-green-600 transition-colors"
            >
              <FaSave />
              <span>저장</span>
            </button>
          )}
          <button
            onClick={onClearAll}
            className="px-4 py-2 bg-red-500 text-white rounded-lg flex items-center gap-2 hover:bg-red-600 transition-colors"
          >
            <FaTrash />
            <span>전체 삭제</span>
          </button>
        </div>
      </div>

      {/* 키워드 섹션 */}
      <div className="bg-white rounded-lg shadow-sm border p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-800">관심 키워드</h2>
          <button
            onClick={handleAddButtonClick}
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            <FaPlus />
            <span>키워드 추가</span>
          </button>
        </div>
        
        <div className="flex flex-wrap gap-3">
          {selectedKeywords.map(keyword => (
            <div
              key={keyword.id}
              className="group relative bg-gray-50 hover:bg-gray-100 rounded-lg px-4 py-2 flex items-center gap-2 transition-colors"
            >
              <span>{keyword.text}</span>
              <span className="text-xs text-gray-500">{keyword.country}</span>
              <div className="hidden group-hover:flex items-center gap-1 ml-2">
                <button
                  onClick={() => {
                    const newCountry = keyword.country === 'KR' ? 'US' : 'KR';
                    onUpdateKeyword({ ...keyword, country: newCountry });
                  }}
                  className="p-1 text-gray-500 hover:text-blue-500 transition-colors"
                >
                  <FaGlobe size={14} />
                </button>
                <button
                  onClick={() => {
                    setEditingKeyword(keyword);
                    setIsEditModalOpen(true);
                  }}
                  className="p-1 text-gray-500 hover:text-blue-500 transition-colors"
                >
                  <FaEdit size={14} />
                </button>
                <button
                  onClick={() => onRemoveKeyword(keyword.id)}
                  className="p-1 text-gray-500 hover:text-red-500 transition-colors"
                >
                  <FaTrash size={14} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 모달 */}
      {isAddModalOpen && (
        <AddKeywordModal
          onClose={() => setIsAddModalOpen(false)}
          onAddKeyword={handleAddKeyword}
        />
      )}

      {/* EditKeywordModal에 수정된 핸들러 전달 */}
      {isEditModalOpen && editingKeyword && (
        <EditKeywordModal
          keyword={editingKeyword}
          onClose={() => setIsEditModalOpen(false)}
          onUpdate={handleUpdateKeyword}
        />
      )}

      {/* 구독 안내 모달 */}
      {isSubscriptionModalOpen && (
        <SubscriptionModal
          onClose={() => setIsSubscriptionModalOpen(false)}
          onUpgrade={onUpgrade}
        />
      )}
    </div>
  );
}

export default KeywordManager; 