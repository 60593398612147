import { useState, useEffect, useMemo } from 'react';
import { FaNewspaper, FaSync } from 'react-icons/fa';
import stringSimilarity from 'string-similarity';
import NewsTabNavigation from '../components/news/NewsTabNavigation';
import NewsList from '../components/news/NewsList';
import KeywordFilterModal from '../components/news/KeywordFilterModal';
import AINewsSection from '../components/news/AINewsSection';
import LoadingSpinner from '../components/common/LoadingSpinner';
import EmptyState from '../components/common/EmptyState';
import Pagination from '../components/common/Pagination';
import { useAuth } from '../contexts/AuthContext';
import { getKeywords, addAnalysisHistory, getAnalysisHistory, incrementAiAnalysisCount } from '../utils/userManager';
import { useNavigate } from 'react-router-dom';
import { useSubscriptionLimits } from '../hooks/useSubscriptionLimits';

function NewsFeed() {
  const { user, userData } = useAuth();
  const navigate = useNavigate();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeKeywords, setActiveKeywords] = useState([]);
  const [keywords, setKeywords] = useState({ selected: [], recommended: [] });
  const [isKeywordModalOpen, setIsKeywordModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [lastUpdated, setLastUpdated] = useState(null);
  const [showAnalysisModal, setShowAnalysisModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [analysisHistory, setAnalysisHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('latest');
  const [error, setError] = useState(null);
  const { limits, usage } = useSubscriptionLimits();

  // 캐시 관련 상수 추가
  const CACHE_KEY = 'newsCache';
  const CACHE_DURATION = 15 * 60 * 1000; // 15분

  // 사용량 제한 체크
  const isAnalysisLimitReached = usage.aiAnalysisCount >= limits.analysisPerMonth;

  // 키워드 초기화
  useEffect(() => {
    const loadUserKeywords = async () => {
      if (user) {
        try {
          const userKeywords = await getKeywords(user.uid);
          setKeywords({
            selected: userKeywords || [],
            recommended: []
          });
          setActiveKeywords(userKeywords || []);
        } catch (error) {
          console.error('키워드 로드 중 오류:', error);
          // Local Storage 폴백 제거하고 빈 배열로 초기화
          setKeywords({ selected: [], recommended: [] });
          setActiveKeywords([]);
        }
      } else {
        // 비로그인 사용자는 빈 배열로 초기화
        setKeywords({ selected: [], recommended: [] });
        setActiveKeywords([]);
      }
    };
    
    loadUserKeywords();
  }, [user]);

  const fetchNews = async () => {
    if (!keywords.selected.length) return;
    
    setLoading(true);
    setError(null);

    // 새로고침이 아닌 경우 캐시 확인
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      const { timestamp, data } = JSON.parse(cached);
      const now = new Date().getTime();
      
      // 캐시가 유효한 경우
      if (now - timestamp < CACHE_DURATION) {
        setNews(data);
        setLastUpdated(new Date(timestamp).toLocaleTimeString());
        setLoading(false);
        return;
      }
    }

    const retryFetch = async (attempt = 1, maxAttempts = 3) => {
      try {
        const response = await fetch('https://fetchnewsfeeds-irpjaw2bta-uc.a.run.app/fetchNewsFeeds', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ keywords: keywords.selected }),
        });

        const responseData = await response.json();

        if (responseData.error) {
          throw new Error(responseData.error);
        }

        if (!response.ok) {
          if (response.status === 503 && attempt < maxAttempts) {
            const waitTime = Math.min(1000 * Math.pow(2, attempt), 8000);
            await new Promise(resolve => setTimeout(resolve, waitTime));
            return retryFetch(attempt + 1, maxAttempts);
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const processedNews = processNews(responseData.results.flatMap(result => {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(result.xmlText, 'text/xml');
          const items = xmlDoc.querySelectorAll('item');
          
          return Array.from(items).map(item => ({
            title: item.querySelector('title')?.textContent || '',
            link: item.querySelector('link')?.textContent || '',
            pubDate: item.querySelector('pubDate')?.textContent || '',
            keyword: result.keyword.text
          }));
        }));

        // 캐시 저장
        localStorage.setItem(CACHE_KEY, JSON.stringify({
          timestamp: new Date().getTime(),
          data: processedNews
        }));

        setNews(processedNews);
        setLastUpdated(new Date().toLocaleTimeString());
        return processedNews;
      } catch (error) {
        console.error('뉴스 로드 중 상세 오류:', error);
        
        if (attempt >= maxAttempts) {
          if (error.message.includes('503')) {
            setError('일시적으로 뉴스를 불러올 수 없습니다. 잠시 후 다시 시도해주세요.');
          } else {
            setError('뉴스를 불러오는 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
          }
          setNews([]);
          return [];
        }
        
        const waitTime = Math.min(1000 * Math.pow(2, attempt), 8000);
        await new Promise(resolve => setTimeout(resolve, waitTime));
        return retryFetch(attempt + 1, maxAttempts);
      }
    };

    try {
      await retryFetch();
    } catch (error) {
      console.error('최종 에러 처리:', error);
      setError('뉴스를 불러오는 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
      setNews([]);
    } finally {
      setLoading(false);
    }
  };

  // useEffect에서는 fetchNews 함수를 직접 호출
  useEffect(() => {
    fetchNews();
  }, [keywords.selected]);

  // 뉴스 카운트 계산
  const newsCountByKeyword = useMemo(() => {
    const counts = {};
    news.forEach(item => {
      item.keywords.forEach(keyword => {
        counts[keyword] = (counts[keyword] || 0) + 1;
      });
    });
    return counts;
  }, [news]);

  // 뉴스 중복 제거 및 키워드 병합 함수
  const processNews = (newsArray) => {
    const newsMap = new Map();
    
    newsArray.forEach(news => {
      let isDuplicate = false;
      
      // 제목에서 출처 추출 (마지막 하이픈 이후 부분)
      const titleParts = news.title.split(' - ');
      const title = titleParts.slice(0, -1).join(' - ').trim();
      const source = titleParts[titleParts.length - 1] || '';
      
      // 이미 처리된 뉴스들과 유사도 비교
      for (const [_, existingNews] of newsMap) {
        const similarity = stringSimilarity.compareTwoStrings(
          existingNews.title.toLowerCase(),
          title.toLowerCase()
        );
        
        if (similarity > 0.8) {  // 80% 이상 유사하면 중복으로 처리
          isDuplicate = true;
          // 기존 뉴스의 키워드 배열에 현재 뉴스의 키워드 추가
          existingNews.keywords = [...new Set([...existingNews.keywords, news.keyword])];
          break;
        }
      }
      
      // 중복이 아닌 경우 새로운 뉴스로 추가
      if (!isDuplicate) {
        newsMap.set(news.link, {
          ...news,
          title: title,  // 출처를 제외한 제목
          source: source,  // 추출된 출처
          keywords: [news.keyword],
          parsedDate: new Date(news.pubDate)
        });
      }
    });
    
    // 날짜순으로 정렬하여 반환
    return Array.from(newsMap.values())
      .sort((a, b) => b.parsedDate - a.parsedDate);
  };

  // 필터링된 뉴스 계산 (중복 제거 추가)
  const filteredNews = useMemo(() => {
    if (activeKeywords.length === 0) return news;
    return news.filter(item =>
      item.keywords.some(keyword =>
        activeKeywords.some(ak => ak.text === keyword)
      )
    );
  }, [news, activeKeywords]);

  // 키워드 토글 처리
  const handleToggleKeyword = (keyword) => {
    if (!keyword) return;
    
    setActiveKeywords(prev => {
      const isActive = prev.some(k => k?.id === keyword.id);
      if (isActive) {
        return prev.filter(k => k?.id !== keyword.id);
      }
      return [...prev, keyword];
    });
  };

  // 날짜 포맷팅 함수
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
  
    // 두 날짜의 시간 차이 계산 (ms 단위)
    const diff = now - date;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    // 오늘 날짜 계산
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  
    // 어제 날짜 계산
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    // 24간 이내인 경우
    if (days < 1) {
      if (hours < 1) {
        return `${minutes}분 전`;
      }
      return `${hours}시간 전`;
    }
  
    // 어제인 경우
    if (date >= yesterday && date < today) {
      return '어제';
    }
  
    // 그 이전인 경우
    return date.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).replace(/\./g, '.').replace(',', '');
  };  

  // 페이지네이션된 뉴스 계산
  const paginatedNews = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredNews.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredNews, currentPage]);

  // 총 페이지 수 계산
  const totalPages = Math.ceil(filteredNews.length / itemsPerPage);

  // 페이지 변경 핸들
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 뉴스 목록이 변경될 때마다 첫 페이지로 이동
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredNews.length]);

  // 상수 정의
  const MAX_VISIBLE_KEYWORDS = 3;  // 최대 표시할 키워드 수

  const handleRefresh = async () => {
    if (loading) return;
    
    // 새로고침 시 캐시 삭제
    localStorage.removeItem(CACHE_KEY);
    
    try {
      await fetchNews();
    } catch (error) {
      console.error('뉴스 새로고침 중 오류:', error);
    }
  };

  const handleAnalyze = async (newsItem) => {
    try {
      setLoading(true);
      const summary = await analyzeNews(newsItem.content);
      
      // Firestore에 분석 결과 저장
      await addAnalysisHistory(user.uid, {
        newsTitle: newsItem.title,
        newsUrl: newsItem.url,
        summary: summary,
        timestamp: new Date()
      });

      // 분석 카운트 증가
      await incrementAiAnalysisCount(user.uid);
      
      setShowAnalysisModal(true);
      loadAnalysisHistory(); // 히스토리 새로고침
    } catch (error) {
      console.error('뉴스 분석 중 오류:', error);
      alert('뉴스 분석 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const loadAnalysisHistory = async () => {
    if (!user) return;
    
    try {
      const history = await getAnalysisHistory(user.uid);
      setAnalysisHistory(history);
    } catch (error) {
      console.error('분석 히스토리 로드 중 오류:', error);
    }
  };

  // 컴포넌트 마운트 시 히스토리 로드
  useEffect(() => {
    loadAnalysisHistory();
  }, [user]);

  const handleUpgrade = () => {
    navigate('/profile', { state: { activeTab: 'subscription' } });
  };

  const renderNavigation = () => (
    <NewsTabNavigation 
      activeTab={activeTab}
      onTabChange={setActiveTab}
      activeKeywords={activeKeywords}
      onKeywordModalOpen={() => setIsKeywordModalOpen(true)}
      onAnalysisClick={() => setShowAnalysisModal(true)}
      onHistoryClick={() => setShowHistoryModal(true)}
      hasHistory={analysisHistory.length > 0}
      isAnalysisLimitReached={isAnalysisLimitReached}
      onUpgrade={handleUpgrade}  // handleUpgrade 함수 전달
    />
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto p-4 sm:p-6">
        {renderNavigation()}
        
        {isKeywordModalOpen && (
          <KeywordFilterModal
            keywords={keywords.selected}
            activeKeywords={activeKeywords}
            onToggleKeyword={handleToggleKeyword}
            newsCountByKeyword={newsCountByKeyword}
            onClose={() => setIsKeywordModalOpen(false)}
          />
        )}
        
        {activeTab === 'latest' ? (
          <>
            <div className="text-sm text-gray-500 mt-2 mb-4 flex items-center gap-2">
              <span>마지막 업데이트: {lastUpdated}</span>
              <button
                onClick={handleRefresh}
                disabled={loading}
                className={`p-1.5 rounded-full hover:bg-gray-100 transition-colors ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                title="새로고침"
              >
                <FaSync className={`w-4 h-4 ${loading ? 'animate-spin' : ''}`} />
              </button>
            </div>
            {loading && <LoadingSpinner />}
            {!loading && filteredNews.length === 0 && <EmptyState />}
            {!loading && filteredNews.length > 0 && (
              <NewsList 
                news={paginatedNews}
                activeKeywords={activeKeywords}
                formatDate={formatDate}
              />
            )}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <AINewsSection 
            news={news}
            formatDate={formatDate}
            showAnalysisModal={showAnalysisModal}
            setShowAnalysisModal={setShowAnalysisModal}
            showHistoryModal={showHistoryModal}
            setShowHistoryModal={setShowHistoryModal}
          />
        )}
      </div>
    </div>
  );
}

export default NewsFeed;