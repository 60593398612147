import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useSubscriptionLimits } from '../../hooks/useSubscriptionLimits';
import { FaCrown, FaCheckCircle } from 'react-icons/fa';

function SubscriptionStatus() {
  const { userData } = useAuth();
  const { limits, usage } = useSubscriptionLimits();
  const subscription = userData?.subscription;

  const getStatusColor = () => {
    switch (subscription?.status) {
      case 'active':
        return 'text-green-500';
      case 'cancelled':
        return 'text-yellow-500';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
      <div className="flex items-center gap-3 mb-4">
        <FaCrown className={`w-6 h-6 ${getStatusColor()}`} />
        <h2 className="text-xl font-bold">구독 상태</h2>
      </div>

      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="text-sm text-gray-600">현재 등급</div>
            <div className="text-lg font-semibold">
              {userData?.tier === 'premium' ? '프리미엄' : '무료'}
            </div>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="text-sm text-gray-600">만료일</div>
            <div className="text-lg font-semibold">
              {subscription?.endDate?.toDate().toLocaleDateString() || '-'}
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <h3 className="font-semibold">이용 현황</h3>
          <div className="space-y-1">
            <div className="flex justify-between text-sm">
              <span>키워드 등록</span>
              <span>{usage.keywordCount || 0} / {limits.keywords}개</span>
            </div>
            <div className="flex justify-between text-sm">
              <span>이번 달 분석 횟수</span>
              <span>{usage.aiAnalysisCount || 0} / {limits.analysisPerMonth}회</span>
            </div>
            <div className="flex justify-between text-sm">
              <span>저장된 히스토리</span>
              <span>{usage.historyCount || 0} / {limits.history}개</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionStatus;
