function NewsItem({ item, activeKeywords, formatDate, maxVisibleKeywords }) {
  return (
    <a 
      href={item.link}
      target="_blank"
      rel="noopener noreferrer"
      className="block bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 p-4 sm:p-6"
    >
      <h2 className="text-base sm:text-lg font-semibold text-gray-800 mb-2">
        {item.title}
      </h2>
      <div className="flex flex-wrap items-center gap-2 text-sm text-gray-500">
        <span>{item.source}</span>
        <span>•</span>
        <span>{formatDate(item.pubDate)}</span>
        <span>•</span>
        <div className="flex flex-wrap gap-1 items-center">
          {item.keywords?.slice(0, maxVisibleKeywords).map((keyword, idx) => (
            <span 
              key={idx} 
              className={`px-2 py-0.5 rounded-full text-sm ${
                activeKeywords.some(ak => ak.text === keyword)
                  ? 'bg-blue-100 text-blue-600'
                  : 'bg-gray-100 text-gray-600'
              }`}
            >
              {keyword}
            </span>
          ))}
          {item.keywords?.length > maxVisibleKeywords && (
            <span 
              className="px-2 py-0.5 rounded-full text-sm bg-gray-100 text-gray-600 cursor-help"
              title={item.keywords.slice(maxVisibleKeywords).join(', ')}
            >
              +{item.keywords.length - maxVisibleKeywords}
            </span>
          )}
        </div>
      </div>
    </a>
  );
}

export default NewsItem; 