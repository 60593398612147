import { useState, useEffect, useRef } from 'react';
import { analyzeNewsTopics, ANALYSIS_OPTIONS } from '../../utils/newsAnalyzer';
import { useAuth } from '../../contexts/AuthContext';
import TopicAnalysisResult from './TopicAnalysisResult';
import AnalysisOptionsModal from './modals/AnalysisOptionsModal';
import AnalysisHistoryModal from './modals/AnalysisHistoryModal';
import { getAnalysisHistory, addAnalysisHistory, incrementAiAnalysisCount } from '../../utils/userManager';
import { useSubscriptionLimits } from '../../hooks/useSubscriptionLimits';
import NewsTabNavigation from './NewsTabNavigation';

const AnalysisCompleteModal = ({ isOpen, onClose, remainingCount, totalCount }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="text-center mb-4">
          <div className="text-green-500 text-4xl mb-3">✓</div>
          <h3 className="text-lg font-semibold mb-2">분석 완료</h3>
          <p className="text-gray-600 text-sm">
            이번 달 남은 AI 분석 횟수: {remainingCount}회
          </p>
        </div>
        <div className="flex justify-center">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

function AINewsSection({
  news = [], 
  formatDate, 
  showAnalysisModal,
  setShowAnalysisModal,
  showHistoryModal,
  setShowHistoryModal
}) {
  const { user } = useAuth();
  const { isWithinLimits, limits, usage } = useSubscriptionLimits();
  const [analysis, setAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [analysisOption, setAnalysisOption] = useState('RECENT_100');
  const [analysisInfo, setAnalysisInfo] = useState(null);
  const [newsCount, setNewsCount] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [loadingText, setLoadingText] = useState('분석 준비 중...');
  const [analysisHistory, setAnalysisHistory] = useState([]);
  const [currentAnalysis, setCurrentAnalysis] = useState(null);
  const [error, setError] = useState(null);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  useEffect(() => {
    if (Array.isArray(news)) {
      setNewsCount(news.length);
    }
  }, [news]);

  useEffect(() => {
    if (analysis) {
      localStorage.setItem('newsAnalysis', analysis);
    }
  }, [analysis]);

  useEffect(() => {
    if (lastUpdated) {
      localStorage.setItem('lastAnalysisTime', lastUpdated);
    }
  }, [lastUpdated]);

  useEffect(() => {
    if (analysisInfo) {
      localStorage.setItem('analysisInfo', JSON.stringify(analysisInfo));
    }
  }, [analysisInfo]);

  useEffect(() => {
    if (analysisHistory.length > 0) {
      localStorage.setItem('analysisHistory', JSON.stringify(analysisHistory));
    }
  }, [analysisHistory]);

  useEffect(() => {
    let interval;
    if (isLoading) {
      const loadingMessages = [
        '뉴스 데이터 분석 중...',
        '주제 그룹화 진행 중...',
        '키워드 추출 중...',
        '관련 뉴스 매칭 중...',
      ];
      let index = 0;
      
      interval = setInterval(() => {
        setLoadingText(loadingMessages[index % loadingMessages.length]);
        index++;
      }, 3000);
    }
    
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isLoading]);

  useEffect(() => {
    if (analysisHistory.length > 0 && lastUpdated) {
      const current = analysisHistory.find(item => item.timestamp === lastUpdated);
      if (current) {
        setCurrentAnalysis(current);
      }
    }
  }, [analysisHistory, lastUpdated]);

  useEffect(() => {
    const loadHistory = async () => {
      if (user) {
        try {
          const history = await getAnalysisHistory(user.uid);
          setAnalysisHistory(history);
          if (history.length > 0) {
            const latest = history[0];
            setAnalysis(latest.analysis);
            setAnalysisInfo(latest.analysisInfo);
            setLastUpdated(latest.timestamp);
            setCurrentAnalysis(latest);
          }
        } catch (error) {
          console.error('히스토리 로드 중 오류:', error);
        }
      }
    };
    
    loadHistory();
  }, [user]);

  const handleAnalyzeClick = async () => {
    if (!Array.isArray(news) || news.length === 0) {
      setError('분석할 뉴스가 없습니다.');
      return;
    }

    const option = ANALYSIS_OPTIONS[analysisOption];
    const effectiveNewsCount = Math.min(news.length, option.maxNews);

    if (!isWithinLimits.analysis()) {
      alert(`이번 달 분석 가능 횟수(${limits.analysisPerMonth}회)를 모두 사용했습니다.`);
      return;
    }

    if (!isWithinLimits.newsPerAnalysis(effectiveNewsCount)) {
      alert(`무료 사용자는 최대 ${limits.maxNewsPerAnalysis}개의 뉴스만 분석할 수 있습니다.`);
      return;
    }

    setShowAnalysisModal(false);
    setIsLoading(true);
    setProgress(0);
    setError(null);
    
    try {
      console.log(`Analyzing with option: ${analysisOption}`);
      const result = await analyzeNewsTopics(news, analysisOption, setProgress);
      console.log('Analysis result:', result);
      await saveAnalysisResult(result.analysis, result.analysisInfo);
      if (user) {
        await incrementAiAnalysisCount(user.uid);
      }
      setShowCompleteModal(true);
    } catch (error) {
      console.error('뉴스 분석 실패:', error);
      setError(error.message);
      setAnalysis(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleHistoryClick = (item) => {
    if (!item?.analysis) return;
    
    setAnalysis(item.analysis);
    setAnalysisInfo(item.analysisInfo);
    setLastUpdated(item.timestamp);
    setCurrentAnalysis(item);
    setShowHistoryModal(false);
  };

  const saveAnalysisResult = async (result, info) => {
    const currentTime = new Date().toLocaleString();
    const analysisData = {
      timestamp: currentTime,
      analysis: result,
      analysisInfo: info,
      newsCount: news.length
    };

    if (user) {
      try {
        await addAnalysisHistory(user.uid, analysisData);
        const updatedHistory = await getAnalysisHistory(user.uid);
        setAnalysisHistory(updatedHistory);
        setCurrentAnalysis(analysisData);
        setAnalysis(result);
        setAnalysisInfo(info);
        setLastUpdated(currentTime);
      } catch (error) {
        console.error('분석 히스토리 저장 중 오류:', error);
      }
    }
  };

  const handleDeleteHistory = (historyId) => {
    setAnalysisHistory(prev => {
      const newHistory = prev.filter(item => item.id !== historyId);
      
      // 로컬 스토리지 업데이트
      if (!user) {
        localStorage.setItem('analysisHistory', JSON.stringify(newHistory));
      }
      
      // 삭제된 항목이 현재 선택된 분석이었다면 초기화
      if (currentAnalysis?.id === historyId) {
        setCurrentAnalysis(newHistory[0] || null);
        setAnalysis(newHistory[0]?.analysis || null);
        setAnalysisInfo(newHistory[0]?.analysisInfo || null);
        setLastUpdated(newHistory[0]?.timestamp || null);
      }
      
      return newHistory;
    });
  };

  return (
    <div className="space-y-4">
      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4">
          {error}
        </div>
      )}

      {lastUpdated && (
        <div className="text-sm text-gray-500 mt-2">
          분석 시각: {lastUpdated.split('. ').slice(0, 3).join('.')} {lastUpdated.split('. ')[3]}
        </div>
      )}

      {!analysis && !isLoading && (
        <div className="bg-white rounded-lg shadow-sm p-8 text-center">
          <div className="text-gray-500">
            AI 분석 버튼을 클릭하여 뉴스를 분석해보세요.
          </div>
        </div>
      )}

      {isLoading && (
        <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6 mb-6">
          <div className="space-y-4">
            <div className="text-center text-sm text-gray-600 mb-2">
              {loadingText}
            </div>
            <div className="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="absolute top-0 left-0 h-full bg-blue-500 transition-all duration-300 animate-pulse"
                style={{ width: `${Math.max(5, progress * 100)}%` }}
              />
              <div 
                className="absolute top-0 left-0 h-full bg-blue-300/50 rounded-full animate-progressBar"
                style={{ width: '100%' }}
              />
            </div>
            <div className="flex justify-between text-sm text-gray-500">
              <span>{Math.round(progress * 100)}% 완료</span>
              <span className="animate-pulse">분석 진행 중...</span>
            </div>
          </div>
        </div>
      )}

      {analysis && (
        <TopicAnalysisResult 
          analysisResult={analysis} 
          newsData={news}
          formatDate={formatDate}
        />
      )}

      <AnalysisOptionsModal
        isOpen={showAnalysisModal}
        onClose={() => setShowAnalysisModal(false)}
        onAnalyze={handleAnalyzeClick}
        analysisOption={analysisOption}
        setAnalysisOption={setAnalysisOption}
        totalNewsCount={newsCount}
      />

      {showHistoryModal && (
        <AnalysisHistoryModal
          isOpen={showHistoryModal}
          onClose={() => setShowHistoryModal(false)}
          history={analysisHistory}
          onSelectHistory={handleHistoryClick}
          currentAnalysis={currentAnalysis}
          onDeleteHistory={handleDeleteHistory}
        />
      )}

      <AnalysisCompleteModal
        isOpen={showCompleteModal}
        onClose={() => setShowCompleteModal(false)}
        remainingCount={limits.analysisPerMonth - ((usage.aiAnalysisCount || 0) + 1)}
        totalCount={limits.analysisPerMonth}
      />
    </div>
  );
}

export default AINewsSection;