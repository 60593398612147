import NewsItem from './NewsItem';

function NewsList({ news, activeKeywords, formatDate }) {
  const MAX_VISIBLE_KEYWORDS = 3;

  return (
    <div className="space-y-2 mb-8">
      {news.map((item, index) => (
        <NewsItem 
          key={index}
          item={item}
          activeKeywords={activeKeywords}
          formatDate={formatDate}
          maxVisibleKeywords={MAX_VISIBLE_KEYWORDS}
        />
      ))}
    </div>
  );
}

export default NewsList; 