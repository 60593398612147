import React from 'react';
import { FaTimes, FaTrash } from 'react-icons/fa';
import { useAuth } from '../../../contexts/AuthContext';
import { deleteAnalysisHistory } from '../../../utils/userManager';

function AnalysisHistoryModal({ isOpen, onClose, history, onSelectHistory, currentAnalysis, onDeleteHistory }) {
  const { user } = useAuth();

  if (!isOpen) return null;

  const handleDelete = async (historyItem, e) => {
    e.stopPropagation(); // 버튼 클릭 시 상위 요소의 클릭 이벤트 방지
    
    if (!window.confirm('이 분석 기록을 삭제하시겠습니까?')) {
      return;
    }

    try {
      if (user) {
        await deleteAnalysisHistory(user.uid, historyItem.id);
      }
      onDeleteHistory(historyItem.id);
    } catch (error) {
      console.error('히스토리 삭제 중 오류:', error);
      alert('삭제 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl max-h-[80vh] overflow-hidden">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">분석 히스토리</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <FaTimes />
            </button>
          </div>

          {!user && (
            <div className="mb-4 p-3 bg-gray-50 rounded-lg text-sm text-gray-600">
              💡 로그인하시면 모든 분석 히스토리를 저장할 수 있습니다.
            </div>
          )}

          <div className="space-y-2 max-h-[50vh] overflow-y-auto">
            {history.map((item, index) => (
              <div
                key={index}
                className={`w-full p-4 rounded-lg text-left border group ${
                  currentAnalysis === item
                    ? 'bg-blue-50 border-blue-500'
                    : 'hover:bg-gray-50'
                }`}
              >
                <div className="flex justify-between items-center">
                  <button
                    onClick={() => onSelectHistory(item)}
                    className="flex-grow text-left"
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-gray-500">{item.timestamp}</span>
                      <span className="text-sm text-gray-500">
                        {item.analysisInfo.analyzedNews}개 뉴스
                      </span>
                    </div>
                  </button>
                  <button
                    onClick={(e) => handleDelete(item, e)}
                    className="ml-2 p-2 text-gray-400 hover:text-red-500 transition-colors"
                    title="삭제"
                  >
                    <FaTrash className="w-4 h-4" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="border-t p-4 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-50 rounded-lg transition-colors"
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
}

export default AnalysisHistoryModal; 