import { Link, useLocation } from 'react-router-dom';
import LoginButton from './auth/LoginButton';

function Header() {
  const location = useLocation();
  
  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-6xl mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <nav className="flex items-center gap-6">
            <Link 
              to="/"
              className={`text-lg font-medium ${
                location.pathname === '/' 
                  ? 'text-blue-600' 
                  : 'text-gray-600 hover:text-blue-600'
              }`}
            >
              Today AI
            </Link>
            <Link
              to="/feed"
              className={`text-lg font-medium ${
                location.pathname === '/feed'
                  ? 'text-blue-600'
                  : 'text-gray-600 hover:text-blue-600'
              }`}
            >
              My Feed
            </Link>
          </nav>
          <LoginButton />
        </div>
      </div>
    </header>
  );
}

export default Header; 