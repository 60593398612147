import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getKeywords, addKeyword, updateKeywordCount, removeKeyword, updateKeyword, clearAllKeywords } from '../utils/userManager';
import { useSubscriptionLimits } from '../hooks/useSubscriptionLimits';
import SubscriptionStatus from '../components/subscription/SubscriptionStatus';
import SubscriptionPlans from '../components/subscription/SubscriptionPlans';
import { FaCrown, FaTag, FaPlus, FaTimes, FaGlobe, FaGoogle } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import KeywordManager from '../components/keywords/KeywordManager';

function ProfilePage() {
  const { user, loginWithGoogle } = useAuth();
  const location = useLocation();
  const { isWithinLimits, limits } = useSubscriptionLimits();
  const [keywords, setKeywords] = useState({ selected: [], recommended: [] });
  const [activeTab, setActiveTab] = useState(
    location.state?.activeTab || 'keywords'
  );
  const [error, setError] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const loadUserKeywords = async () => {
    if (user) {
      try {
        const userKeywords = await getKeywords(user.uid);
        setKeywords({
          selected: userKeywords || [],
          recommended: []
        });
        setError(null);
      } catch (error) {
        console.error('키워드 로드 중 오류:', error);
        setError('키워드를 불러오는 중 오류가 발생했습니다.');
      }
    }
  };

  useEffect(() => {
    loadUserKeywords();
  }, [user]);

  const handleAddKeyword = async (newKeyword) => {
    if (!isWithinLimits.keywords(keywords.selected.length + 1)) {
      alert(`키워드는 최대 ${limits.keywords}개까지만 등록할 수 있습니다.`);
      return;
    }
    
    setKeywords(prev => ({
      ...prev,
      selected: [...prev.selected, { ...newKeyword, id: Date.now().toString() }]
    }));
    setHasChanges(true);
    setError(null);
  };

  const handleRemoveKeyword = (id) => {
    setKeywords(prev => ({
      ...prev,
      selected: prev.selected.filter(k => k.id !== id)
    }));
    setHasChanges(true);
    setError(null);
  };

  const handleSave = async () => {
    try {
      // 기존 키워드 모두 삭제
      await clearAllKeywords(user.uid);
      
      // 새로운 키워드들 추가
      await Promise.all(keywords.selected.map(keyword => 
        addKeyword(user.uid, keyword)
      ));
      
      await updateKeywordCount(user.uid, keywords.selected.length);
      setHasChanges(false);
      alert('키워드가 저장되었습니다.');
    } catch (error) {
      console.error('키워드 저장 중 오류:', error);
      setError('키워드 저장 중 오류가 발생했습니다.');
    }
  };

  const handleClearAll = async () => {
    if (!window.confirm('모든 키워드를 삭제하시겠습니까?')) return;
    
    try {
      await clearAllKeywords(user.uid);
      setKeywords({ selected: [], recommended: [] });
      await loadUserKeywords(); // DB 상태와 동기화
    } catch (error) {
      console.error('키워드 전체 삭제 중 오류:', error);
      setError('키워드 전체 삭제 중 오류가 발생했습니다.');
    }
  };

  const handleUpdateKeyword = async (keyword) => {
    try {
      const oldKeyword = keywords.selected.find(k => k.id === keyword.id);
      
      setKeywords(prev => ({
        ...prev,
        selected: prev.selected.map(k => k.id === keyword.id ? keyword : k)
      }));
      
      await updateKeyword(user.uid, keyword.id, keyword);
      setError(null);
    } catch (error) {
      console.error('키워드 수정 중 오류:', error);
      setError('키워드 수정 중 오류가 발생했습니다.');
      setKeywords(prev => ({
        ...prev,
        selected: prev.selected.map(k => k.id === keyword.id ? oldKeyword : k)
      }));
    }
  };

  const renderLoginRequired = () => (
    <div className="max-w-4xl mx-auto p-4 text-center">
      <div className="bg-white rounded-lg shadow-sm border p-8 mt-8">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">프로필</h1>
        <p className="text-gray-600 mb-6">
          프로필을 관리하려면 로그인이 필요합니다.
        </p>
        <button
          onClick={loginWithGoogle}
          className="flex items-center gap-2 px-6 py-3 bg-white text-gray-700 rounded-lg border hover:bg-gray-50 transition-colors mx-auto"
        >
          <FaGoogle className="text-red-500" />
          Google 로그인
        </button>
      </div>
    </div>
  );

  const renderMainContent = () => (
    <div className="max-w-4xl mx-auto p-4">
      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4">
          {error}
        </div>
      )}
      
      <div className="flex gap-4 mb-6">
        <button
          onClick={() => setActiveTab('keywords')}
          className={`px-4 py-2 rounded-lg ${
            activeTab === 'keywords' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
        >
          키워드 관리
        </button>
        <button
          onClick={() => setActiveTab('subscription')}
          className={`px-4 py-2 rounded-lg ${
            activeTab === 'subscription' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
        >
          구독 현황
        </button>
      </div>

      {activeTab === 'keywords' ? (
        <KeywordManager
          keywords={keywords}
          onAddKeyword={handleAddKeyword}
          onUpdateKeyword={handleUpdateKeyword}
          onRemoveKeyword={handleRemoveKeyword}
          onSave={handleSave}
          onClearAll={handleClearAll}
          hasChanges={hasChanges}
          onUpgrade={() => setActiveTab('subscription')}
        />
      ) : (
        <>
          <SubscriptionStatus />
          <SubscriptionPlans />
        </>
      )}
    </div>
  );

  return user ? renderMainContent() : renderLoginRequired();
}

export default ProfilePage; 