import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

function TopicNewsModal({ topic, newsList, formatDate, onClose }) {
  useEffect(() => {
    // 모달이 열릴 때 body 스크롤 막기
    document.body.style.overflow = 'hidden';
    
    // 모달이 닫힐 때 body 스크롤 복원
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  // 배경 클릭 시 모달 닫기
  const handleBackgroundClick = (e) => {
    // 배경 클릭이 아니라 내부 클릭인 경우에는 onClose를 호출하지 않음
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
      onClick={handleBackgroundClick} // 배경 클릭 시 onClose 호출
    >
      <div 
        className="bg-white rounded-lg w-full max-w-2xl max-h-[80vh] flex flex-col"
        onClick={(e) => e.stopPropagation()} // 모달 내용 클릭 시 이벤트 전파 막기
      >
        <div className="p-6 border-b flex-shrink-0">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-gray-800">
              {topic}
              <span className="text-sm text-gray-500 ml-2">
                ({newsList.length}건)
              </span>
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <FaTimes />
            </button>
          </div>
        </div>
        <div className="overflow-y-auto flex-grow p-6 space-y-4">
          {newsList.map((news, idx) => (
            <a
              key={idx}
              href={news.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block border border-gray-100 rounded-lg p-4 hover:bg-gray-50 transition-colors duration-200"
            >
              <h3 className="text-gray-800 font-medium mb-2">
                {news.title}
              </h3>
              <div className="flex flex-wrap items-center gap-2 text-sm text-gray-500">
                <span>{news.source}</span>
                {news.pubDate && (
                  <>
                    <span>•</span>
                    <span>{formatDate(news.pubDate)}</span>
                  </>
                )}
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TopicNewsModal;
