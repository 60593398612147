import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { FaCheckCircle } from 'react-icons/fa';

const PLANS = [
  {
    id: 'monthly',
    name: '월간 구독',
    price: 2900,
    period: '월',
    features: [
      '키워드 50개 등록',
      '월 100회 AI 분석',
      '분석 히스토리 100개 저장',
      '500개 뉴스 동시 분석'
    ]
  },
  {
    id: 'yearly',
    name: '연간 구독',
    price: 29000,
    period: '년',
    features: [
      '월간 구독의 모든 기능',
      '16% 할인'
    ]
  }
];

function SubscriptionPlans() {
    const { userData } = useAuth();
    const isPremium = userData?.tier === 'premium';
  
    const handleSubscribe = async (planId) => {
      // TODO: 실제 결제 연동
      alert('현재 개발 중입니다.');
    };
  
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-xl font-bold mb-6">구독 플랜</h2>
        <div className="grid md:grid-cols-2 gap-6">
          {PLANS.map((plan) => (
            <div
              key={plan.id}
              className="border rounded-lg p-6 flex flex-col justify-between"
            >
              <div>
                <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                <div className="text-3xl font-bold mb-4">
                  ₩{plan.price.toLocaleString()}
                  <span className="text-sm text-gray-500">/{plan.period}</span>
                </div>
                <ul className="space-y-2 mb-6">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center gap-2">
                      <FaCheckCircle className="text-green-500 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              {/* CTA 버튼 */}
              <button
                onClick={() => handleSubscribe(plan.id)}
                disabled={isPremium}
                className={`w-full py-2 px-4 rounded-lg mt-4 ${
                  isPremium
                    ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
              >
                {isPremium ? '이미 구독 중' : '구독하기'}
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  export default SubscriptionPlans;