import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';

function LoginButton() {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (user) {
    return (
      <button
        onClick={() => navigate('/profile')}
        className="flex items-center gap-2"
      >
        <img 
          src={user.photoURL} 
          alt={user.displayName} 
          className="w-8 h-8 rounded-full cursor-pointer"
        />
        <span className="text-sm text-gray-700">{user.displayName}</span>
      </button>
    );
  }

  return (
    <button
      onClick={() => navigate('/profile')}
      className="flex items-center gap-2 px-4 py-2 bg-white text-gray-700 rounded-lg border hover:bg-gray-50 transition-colors"
    >
      <FaUser />
      <span>프로필</span>
    </button>
  );
}

export default LoginButton; 